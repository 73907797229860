//variables
$margin-bottom: 1.5rem !default;
$message-warning-color: #edd06a !default;
$message-error-color: #930 !default;
$message-notification-color: #69c !default;
$message-success-color: #71a13f !default;

$border-radius: 0 !default;

.c7-message--warning {
  color: $message-warning-color;
}

div.c7-message--warning {
  background-color: $message-warning-color;
  border-radius: $border-radius;
  color: #fff;
  margin: 0 auto $margin-bottom;
  padding: 1rem;
  text-align: center;
}

p.c7-message--warning {
  color: $message-warning-color;
  text-align: center;
}

.c7-message--error {
  color: $message-error-color;
  text-align: center;
}

div.c7-message--error {
  background-color: $message-error-color;
  border-radius: $border-radius;
  color: #fff;
  margin: 0 auto $margin-bottom;
  padding: 1rem;
  text-align: center;

  .c7-account-tile__section &,
  .c7-account-row & {
    background-color: rgba($message-error-color, 1);
    border-radius: 3px;
    color: #fff;
    margin: 0.25rem auto 1.25rem;
    max-width: 100%;
    padding: 0.5rem;
    position: relative;
    text-align: left;

    &::after {
      border: 1px solid #fff;
      border-radius: 50%;
      content: '';
      display: block;
      height: 24px;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
    }

    &::before {
      content: '!';
      font-size: 14px;
      position: absolute;
      right: 20px;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

p.c7-message--error {
  color: $message-error-color;
  text-align: center;
}

.c7-message--notification {
  color: $message-notification-color;
}

div.c7-message--notification {
  background-color: $message-notification-color;
  border-radius: $border-radius;
  color: #fff;
  margin: 0 auto $margin-bottom;
  padding: 1rem;
  text-align: center;
}

p.c7-message--notification {
  color: $message-notification-color;
  text-align: center;
}

.c7-message--success {
  color: $message-success-color;
}

div.c7-message--success {
  background-color: $message-success-color;
  border-radius: $border-radius;
  color: #fff;
  margin: 0 auto $margin-bottom;
  padding: 1rem;
  text-align: center;
}

p.c7-message--success {
  color: $message-success-color;
  text-align: center;
}

.c7-message-hard {
  background-color: $message-error-color;
  color: #fff;
  left: 0;
  padding: 0.75rem;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 999;
}

.c7-message--empty-cart {
  background: rgba(0, 0, 0, 0.05);
  border-radius: $border-radius;
  cursor: pointer;
  margin: 0 auto $margin-bottom;
  max-width: 80%;
  padding: 4rem 1rem 1rem;
  position: relative;
  text-align: center;
  text-align: center;

  &::after {
    background-image: url('/assets/beta/images/c7-icons/icon-circle-plus.svg');
    background-position: top center;
    background-repeat: no-repeat;
    content: '';
    height: 36px;
    left: 50%;
    opacity: 0.2;
    position: absolute;
    top: 1rem;
    transform: translateX(-50%);
    width: 36px;
  }
}

//specific customer messages
.c7-message {
  &--checkout-legal {
    text-align: center;
  }

  &--login-questions {
    text-align: center;
  }

  &--login-security {
    text-align: center;
  }

  &--club-note {
    text-align: center;
  }

  &--empty-cart {
    text-align: center;
  }
}
