.c7-checkout-options {
  margin: 1rem auto 2rem;
  width: 100%;

  @media screen and (min-width: 40rem) {
    display: flex;
    justify-content: center;
  }

  a {
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    color: #111;
    display: block;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto 1.5rem;
    max-width: 300px;
    position: relative;
    text-align: center;
    transition: background 0.2s ease-in;
    width: 100%;

    @media screen and (min-width: 40rem) {
      margin: 0 1rem;
    }

    &:hover {
      background: #eee;
      border: 1px solid #999;
      text-decoration: none;
    }

    img {
      max-height: 280px;
    }

    p {
      margin-bottom: 0;
    }

  }

  &__image {
    padding: 1rem;
  }

  &__content {
    padding: 0 1rem 1rem;
    @media screen and (min-width: 40rem) {
      margin-top: auto;
    }

    &--skip {
      margin-top: 0;
    }
  }
}
