//variables
$margin-bottom: 1.5rem !default;

.c7-product-quickshop {
  display: block;
  margin: 0 0 3rem;
  width: 100%;

  &__row {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;

    @media screen and (min-width: 768px) {
      margin-bottom: 0;
    }

    a {
      color: #24374f;
      text-decoration: none;

      &:hover {
        color: #000;
      }
    }

    & > div {
      padding: 0 0.25rem;

      @media screen and (min-width: 768px) {
        padding: 0.5rem 0.25rem;
      }

      &:first-child {
        text-align: left;
        width: 20%;
      }

      &:nth-child(2) {
        text-align: left;
        width: 80%;

        @media screen and (min-width: 768px) {
          width: 40%;
        }
      }

      &:nth-child(3) {
        margin-left: 20%;
        text-align: left;
        width: 20%;

        @media screen and (min-width: 768px) {
          margin-left: 0;
          text-align: right;
          width: 15%;
        }
      }

      &:nth-child(4) {
        text-align: right;
        width: 20%;

        @media screen and (min-width: 768px) {
          width: 15%;
        }
      }

      &:nth-child(5) {
        text-align: right;
        width: 20%;

        @media screen and (min-width: 768px) {
          width: 10%;
        }
      }
    }
  }

  &__summary {
    align-items: center;
    display: flex;
    justify-content: flex-end;

    & > div {
      padding: 0.5rem;
    }
  }
}
