.c7-icon {
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;

  margin: 0 0.35rem 0 0;
  opacity: 0.85;
  transition: opacity 300ms ease-out;
  vertical-align: middle;

  &:hover {
    opacity: 1;
    text-decoration: none;
  }

  //Order History Detail
  &--help {
    background-image: url('https://cdn.commerce7.com/assets/beta/images/c7-icons/icon-question-circle.svg');
    padding-left: 1.5rem;
  }

  &--smartphone {
    background-image: url('https://cdn.commerce7.com/assets/beta/images/c7-icons/icon-smartphone.svg');
    padding-left: 1.5rem;
  }

  &--calendar {
    background-image: url('https://cdn.commerce7.com/assets/beta/images/c7-icons/icon-calendar.svg');
    padding-left: 1.5rem;
  }

  //Login
  &--email {
    background-image: url('https://cdn.commerce7.com/assets/beta/images/c7-icons/icon-email.svg');
    height: 2.5rem;
    width: 2.5rem;
  }

  &--phone {
    background-image: url('https://cdn.commerce7.com/assets/beta/images/c7-icons/icon-phone.svg');
    height: 2.5rem;
    width: 2.5rem;
  }
}

.c7-icon--facebook {
  background-color: #3b5998 !important;
  background-image: url('https://cdn.commerce7.com/assets/beta/images/c7-icons/icon-facebook.svg') !important;
  background-repeat: no-repeat;
  border: 0 !important;
  border-radius: 0.15rem;
  color: #fff !important;
  font-size: 0.875rem !important;
  height: 2.5rem !important;
  margin-bottom: 1.5rem !important;
  padding-left: 48px !important;
  padding-right: 0.75rem !important;
  width: auto !important;

  &:hover {
    background-color: #3b5998;
    border: 0;
  }
}

.c7-icon--google {
  border: 0 !important;
  margin-bottom: 1.5rem !important;
}

.c7-button-alt.c7-icon {
  background-position: center left 10px;
  background-size: 24px;
  padding-left: 2.5rem;
}
