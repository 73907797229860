//variables
$margin-bottom: 1.5rem !default;


.c7-pagination {
  margin: 0 auto $margin-bottom;
  text-align: center;
  width: 100%;


  //Form types
  select {
    box-shadow: none;

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }


  select {
    cursor: pointer;
    display: block;
    height: auto;
    height: 41px;
    line-height: 1.5;
    margin: 0;
    padding: 0 0.5rem;
    position: relative;
    width: 100%;
  }


  .c7-selectboxWrap {
    display: inline-block;
    position: relative;
    width: 100%;
    width: 64px;

    &::after {
      border: 0.4rem solid transparent;
      border-top-color: currentColor;
      content: '';
      opacity: 0.5;
      pointer-events: none;
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-25%);
    }
  }

  &__amount {
    display: inline-block;
    margin-right: 1rem;
  }

  &__pages {
    display: inline-block;
    margin-bottom: 1rem;

    a {
      background: #eee;
      border: 1px solid #eee;
      border-radius: 0.25em;
      color: #111;
      display: inline-block;
      margin: 0 0.25rem;
      min-width: 44px;
      padding: 0.5rem;
      text-align: center;

      &:hover {
        border: 1px solid #aaa;
        color: #000;
        text-decoration: none;
      }

      &.active {
        background-color: #333;
        color: #fff;
      }
    }

    span {
      display: inline-block;
      min-width: 44px;
      padding: 0.5rem 0.25rem;
      text-align: center;

      &.current {
        background: #f4f4f4;
        border: 1px solid #ccc;
        border-radius: 0.25em;
        color: #000;
        font-weight: bold;
        margin: 0 0.25rem;
        text-align: center;
      }
    }

    &__records {
      font-style: italic;
      margin: 0.5rem auto;

      @media screen and (min-width: 48rem) {
        display: inline-block;
        margin: 0;
        padding-left: 1rem;
      }

      &__range {
        //display: none !important;
        display: inline-block;
        margin-right: 1rem;
      }
    }

  }

}
