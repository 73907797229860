//variables
$margin-bottom: 1.5rem !default;

.c7-checkout-breadcrumbs {
  margin-bottom: $margin-bottom * 2;

  div {
    color: #666;
    display: inline-block;
    font-size: 0.75rem;

    &.active {
      color: #000;
    }

    &::after {
      content: '\00a0\00a0\203A\00a0\00a0';

    }

    &:last-child {
      &::after {
        content: '';
      }
    }
  }
}
