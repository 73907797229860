//c7:Club Shipment

//variables
$margin-bottom: 1.5rem !default;

.c7-checkout-summary {
  background: #f8f8f8;
  border-bottom: 1px solid #e8e4de;
  display: none;
  height: auto;
  margin-bottom: 1.25rem;
  padding: 0.5rem 0.5rem 1.5rem;
  position: relative;
  right: auto;
  top: auto;
  transform: none;
  width: auto;
  width: 100%;

  &.c7-visible {
    display: block;
  }

  @media screen and (min-width: 25em) {
    padding: 1.5rem 1.5rem 2.5rem;
  }

  @media screen and (min-width: 62rem) {
    align-self: flex-start;
    border-bottom: 0;
    border-radius: 0;
    border-right: 0;
    border-top: 0;
    display: block;
    margin: 0;
    max-width: 500px;

    min-height: 100vh;
    order: 2;
    padding-top: 3rem;
    position: sticky;
    top: 0;
    width: 45%;
  }

  &__title {
    background: url('/assets/beta/images/c7-icons/cart.svg') no-repeat center
      left 2px;
    background-size: contain;
    display: block;
    margin-bottom: 1.5rem;
    padding-left: 32px;
    position: relative;

    span {
      display: block;
      font-size: 1rem;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      @media screen and (min-width: 40rem) {
        font-size: 1.125rem;
      }
    }

    a {
      background: url('/assets/beta/images/c7-icons/icon-toggle.svg') center
        center no-repeat;
      display: inline-block;
      height: 10px;
      width: 20px;

      @media screen and (min-width: 40rem) {
        display: none;
      }
    }

    &--hide-mobile {
      display: none;

      @media screen and (min-width: 62rem) {
        display: block;
      }
    }
  }

  &__notes {
    border-bottom: 1px solid #ccc;
    font-size: 0.875rem;
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .c7-form {
    border-top: 1px solid $border-color;
    padding-top: 1rem;
  }

  &__buttons {
    display: none;
    text-align: center;

    @media screen and (min-width: 48rem) {
      display: block;
    }
  }

  .c7-cart-items,
  .c7-cart-summary {
    @media screen and (max-width: 61.99rem) {
      margin: 0 auto;
      max-width: 37rem;
    }
  }

  &__reservation-items {
    span {
      display: inline-block;
      font-weight: bold;
      padding: 0.25rem 0;
      width: 125px;

      + span {
        padding-right: 10px;
        width: auto;
      }
    }
  }

  &__strikethrough {
    text-decoration: line-through;
  }
}

.c7-order__buttons {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
}
