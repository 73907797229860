//c7:account__clubs

//variables
$border-color: #eee !default;
$margin-bottom: 1.5rem !default;
$max-member-width: 50rem !default;

@mixin panel {
  border: 1px solid $border-color;
  border-radius: 5px;
  margin-bottom: $margin-bottom;
  padding: 0.5rem 0.5rem 0;

  @media screen and (min-width: 25rem) {
    padding: 1.5rem 1.5rem 0;
  }
}

.c7-account__clubs {
  margin: 0 auto ($margin-bottom * 2);
  max-width: $max-member-width;
  text-align: center;

  h2 {
    text-align: center;
  }

}

.c7-account__club-status {
  text-align: left;

  @media screen and (min-width: 60rem) {
    padding: 1.5rem 1.5rem 0;
  }

  & + div {
    border-top: 1px solid $border-color;
    margin-top: 2rem;
    padding-top: 2rem;
  }

  h3 {
    em {
      font-size: 0.75em;
      opacity: 0.5;
      text-transform: lowercase;
    }
  }
}
