//c7:Cart

//variables
$margin-bottom: 1.5rem !default;

.c7-cart-items__title__description { 
  font-weight: normal; 
  margin-top: 5px; }

.c7-cart {
  background: #fff;
  border-left: 1px solid #ccc;
  box-sizing: border-box;
  height: 100vh;
  max-width: 500px;
  overflow-y: auto;
  padding: 2rem 1.5rem;
  position: fixed;
  right: 0;
  text-align: left;
  top: 0;
  transform: translateX(100%);
  transition: all 0.5s ease-in;
  visibility: hidden;
  width: 100%;
  z-index: 900;

  h1 {
    border-bottom: 1px solid #999;
    font-size: 1.125rem;
    margin-bottom: 1rem;
    padding-bottom: 0.65em;
  }

  &__loading {
    padding: 1.5rem;
    text-align: center;

    &__animation {
      display: block;
      height: 16px;
      margin: 0 auto;
      position: relative;
      width: 38px;

      div {
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
        background: #999;
        border-radius: 50%;
        height: 6px;
        position: absolute;
        top: 50%;
        width: 6px;

        &:nth-child(1) {
          animation: dot1 0.6s infinite;
          left: 6px;
        }

        &:nth-child(2) {
          animation: dot2 0.6s infinite;
          left: 6px;
        }

        &:nth-child(3) {
          animation: dot2 0.6s infinite;
          left: 17px;
        }

        &:nth-child(4) {
          animation: dot3 0.6s infinite;
          left: 27px;
        }
      }
    }
  }

  @keyframes pulse {
    0% {
      color: rgba(0, 0, 0, 0.4);
    }

    50% {
      color: rgba(0, 0, 0, 1);
    }

    100% {
      color: rgba(0, 0, 0, 0.4);
    }
  }

  &__close {
    border: 1px solid #999;
    border-radius: 50%;
    color: #999;
    display: inline-block;
    height: 32px;
    line-height: 32px;
    opacity: 0;
    position: absolute;
    right: 1.5rem;
    text-align: center;
    top: 1rem;
    transition: all 0.5s ease-in;
    width: 32px;

    &:hover {
      border-color: #111;
      color: #111;
      text-decoration: none;
    }
  }

  &__carrot {
    background: #666;
    border-radius: $border-radius;
    color: #eee;
    font-size: 0.8125rem;
    margin: 2rem auto;
    max-width: 86%;
    padding: 1rem 2rem;
    text-align: center;

    p {
      font-size: 0.8125rem;
      margin: 0;
      padding: 0;
    }
  }

  &__cart-carrot {
    padding: 1rem;

    &-side-cart-top {
      background: rgba(255, 255, 255, 0.9);
      margin-bottom: 1rem;

      p:last-child {
        margin-bottom: 0;
      }
    }

    &-side-cart-bottom {
      background: rgba(255, 255, 255, 0.9);
      margin-bottom: 2rem;

      p:last-child {
        margin-bottom: 0;
      }
    }

    &-cart-page-inline {
      background: #faf8f5;
      margin-bottom: 2rem;

      p:last-child {
        margin-bottom: 0;
      }
    }

    &_product {
      align-items: center;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      max-width: 360px;
      padding-top: 1rem;

      position: relative;

      > img {
        padding-right: 10px;
        width: 40px;
      }

      .c7-cart-items__price {
        align-self: flex-start;
      }

      &_title {
        width: calc(100% - 50px - 20%);

        a {
          font-size: 0.875em;
          margin-top: 0.5rem;
        }
      }

      &_subtitle {
        font-size: 0.875em;
        font-style: italic;
      }

      button {
        margin-top: 0.5rem;
      }

      .c7-button-small {
        padding: 0.5em;
      }
    }
  }

  &__buttons {
    text-align: center;

    .c7-cart__wrapper & {
      text-align: right;
    }

    a {
      margin: 0 0.5rem;
      vertical-align: middle;

      @media screen and (max-width: 420px) {
        display: block;
        margin: 0.65rem 0;
      }
    }

    &--top {
      display: none;
      margin-bottom: $margin-bottom;
    }
  }

  &.c7-visible {
    box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.25);
    transform: translateX(0);
    visibility: visible;

    .c7-cart__close {
      opacity: 1;
    }
  }

  .c7-cart-items__title {
    a {
      font-size: 0.875em;
    }
  }

  .c7-cart-items__title__sku {
    display: none;
  }

  .c7-cart-items__title__sku-description {
    display: none;
  }

  .c7-cart-items__title__variant {
    font-size: 0.875em;
    font-style: italic;
  }

  .c7-button-alt-small {
    padding: 0.25em 0.15em;
  }

  //cart page
  &__wrapper {
    margin: 0 auto 2rem;
    max-width: 50rem;

    .c7-cart__buttons--top {
      border-bottom: 1px solid $border-color;
      display: block;
      padding-bottom: $margin-bottom;
    }
  }

  &__summary {
    border-top: 1px solid $border-color;
    padding-top: $margin-bottom;

    @media screen and (min-width: 30em) {
      display: flex;
    }

    &__message {
      padding: 1rem;

      p {
        font-size: 0.875rem;
      }

      @media screen and (min-width: 30em) {
        margin-bottom: $margin-bottom;
        padding: 0.5rem 1rem;
        width: 50%;
      }

      a {
        display: inline-block;
        margin-bottom: 0.5rem;
      }

      .c7-form {
        max-height: 0;
        opacity: 0;
        transition: all 300ms ease-out;
        visibility: hidden;
      }

      .c7-visible {
        max-height: 400px;
        opacity: 1;
        visibility: visible;
      }

      ul {
        list-style: none;
        margin: 0 0 1.5rem;
        padding: 0;

        li {
          margin-bottom: 0.5rem;
          position: relative;

          &::before {
            content: '-';
            left: 0;
            position: absolute;
          }

          .c7-discount-title {
            font-style: italic;
          }

          .c7-discount-amount {
            display: block;
            font-weight: bold;
          }
        }
      }
    }

    &__totals {
      @media screen and (min-width: 30em) {
        width: 50%;
      }
    }
  }
}

.c7-cart__promo__summary {
  ul {
    list-style: none;
    margin: 0 0 1.5rem;
    padding: 0;

    li {
      margin-bottom: 0.5rem;
      padding-left: 1rem;
      position: relative;

      &::before {
        content: '-';
        left: 0;
        position: absolute;
      }

      .c7-discount-title {
        font-style: italic;
        font-weight: bold;
      }

      .c7-discount-amount {
        display: block;
        font-weight: bold;
      }
    }
  }
}

@keyframes dot1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}
@keyframes dot3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}
@keyframes dot2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(10px, 0);
  }
}
