//c7:account__address-book

//variables
$margin-bottom: 1.5rem !default;

.c7-account__address-book {
  h2 {
    text-align: center;
  }

}

