//c7:account__menu

//variables
$margin-bottom: 1.5rem !default;

.c7-account-menu {
  margin-bottom: $margin-bottom;
  text-align: center;

  a {
    border-right: 1px solid #ccc;
    color: #333;
    display: inline-block;
    font-size: 0.875rem;
    padding: 0.25rem 1rem;

    &:last-child {
      border-right: 0;
    }
  }
}
