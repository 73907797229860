//buttons
$button-font-family: Frutiger, 'Frutiger Linotype', Univers, Calibri, Tahoma,
  Geneva, Arial, sans-serif !default;
$button-font-size: 1rem;

//button colors
$button-color: #fff !default;
$button-background: #333 !default;
$button-outline: #333 !default;

$button-color-hover: #fff !default;
$button-background-hover: #000 !default;
$button-outline-hover: #000 !default;

//button alt colors
$button-alt-color: #999 !default;
$button-alt-background: transparent !default;
$button-alt-outline: #ccc !default;

$button-alt-color-hover: #333 !default;
$button-alt-background-hover: transparent !default;
$button-alt-outline-hover: #999 !default;

//Default Button
@mixin button {
  background-color: $button-background;
  border: 1px solid $button-outline;
  color: $button-color;
  cursor: pointer;
  display: inline-block;
  font-family: $button-font-family;
  font-size: $button-font-size;
  line-height: 1;
  margin: 0;
  padding: 0.5rem 1.5rem;
  text-align: center;
  text-decoration: none;

  &:hover {
    background-color: $button-background-hover;
    border: 1px solid $button-outline-hover;
    color: $button-color-hover;
    text-decoration: none;
  }

  &:focus {
    outline: 0;
  }
}

button[disabled] {
  background: #999;
  color: #fff;

  &:hover {
    background: #999;
    color: #fff;
  }
}

.c7-button {
  @include button();
}

.c7-button-small {
  @include button();
  font-size: 0.75rem;
  padding: 0.35rem 1rem;
}

//Alternative Button
@mixin button-alt {
  background-color: $button-alt-background;
  border: 1px solid $button-alt-outline;
  color: $button-alt-color;
  cursor: pointer;
  display: inline-block;
  font-family: $button-font-family;
  line-height: 1;
  margin: 0;
  padding: 0.5rem 1.5rem;
  text-align: center;
  text-decoration: none;

  &:hover {
    background-color: $button-alt-background-hover;
    border: 1px solid $button-alt-outline-hover;
    color: $button-alt-color-hover;
    text-decoration: none;
  }

  &:focus {
    outline: 0;
  }
}

.c7-button-alt {
  @include button-alt();
}

.c7-button-alt-small {
  @include button-alt();
  font-size: 0.75rem;
  padding: 0.35rem 1rem;
}
