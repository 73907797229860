//button colors
$calendar-accent: #000 !default;

.rdtPicker {
  td {
    color: #111;
    font-weight: bold;

    &.rdtActive {
      background-color: $calendar-accent;

      &:hover {
        background-color: $calendar-accent;
      }
    }

    &.rdtToday {
      &::before {
        border-bottom: 7px solid $calendar-accent;
      }
    }

    &.rdtDisabled {
      color: #b5b5b5;
      font-weight: normal;

      &:hover {
        color: #b5b5b5;
      }
    }
  }

}
