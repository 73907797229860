//variables
$margin-bottom: 1.5rem !default;
$link-color: #333 !default;
$border-color: #ccc !default;

.c7-product-collection {
  min-height: 600px;
  transition: all 300ms ease-out;

  @media screen and (min-width: 48rem) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.c7-product {
  margin-bottom: 2rem;
  position: relative;
  text-align: center;

  @media screen and (min-width: 48rem) {
    display: flex;
    position: relative;
    text-align: left;
    width: calc(50% - 2rem);
  }

  &__title {
    a {
      color: $link-color;
      text-decoration: none;
    }
  }

  &__photo {
    text-align: center;

    img {
      max-height: 360px;
    }
  }

  &__info {
    @media screen and (min-width: 48rem) {
      width: 65%;
    }
  }

  &__login-message {
    p {
      font-style: italic;
    }
  }

  &__link {
    a {
      color: $link-color;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      &::after {
        content: '\00a0\00bb';
      }
    }
  }

  &__variant {
    &__price {
      font-size: 1.4rem;
      margin-bottom: 0.5rem;

      &__sale {
        font-weight: bold;
      }

      &__compare {
        text-decoration: line-through;
      }

      &__title {
        font-size: 0.75em;

        &::before {
          content: '/';
          margin: 0 0.25rem 0 0;
        }
      }
    }

    &__select {
      appearance: none;
      background: url('/assets/beta/images/c7-icons/select-background.svg')
        no-repeat center right 12px #f8f8f8;
      background-size: 21px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      color: #111;
      font-size: 0.875rem;
      margin-bottom: 0.5rem;
      max-width: 198px;
      min-width: 100px;
      padding: 0.65rem 2.5rem 0.65rem 0.75rem;
      text-indent: 0.01px;
      width: 100%;
    }

    &__add-to-cart {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      @media screen and (min-width: 48rem) {
        justify-content: flex-start;
      }

      label {
        margin-right: 0.5rem;
      }

      input[type='text'] {
        border: 1px solid rgba(0, 0, 0, 0.2);
        height: 42px;
        padding: 0 0.5rem;
        text-align: center;
        width: 3rem;
      }

      &__select {
        appearance: none;
        background: url('/assets/beta/images/c7-icons/select-background.svg')
          no-repeat center right 12px #f8f8f8;
        background-size: 21px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        color: #111;
        font-size: 0.875rem;
        max-width: 125px;
        min-width: 75px;
        padding: 0.65rem 2.5rem 0.65rem 0.75rem;
        text-indent: 0.01px;
        width: 100%;
      }

      .c7-button {
        flex-shrink: 0;
      }

      .c7-button__club-shipment {
        background: transparent;
        border: 1px solid #000;
        color: #000;
        margin-top: 8px;
      }
    }
  }

  &__error {
    color: $message-error-color;
    margin-bottom: 0.5rem;
  }

  &--featured {
    background: #efefef;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    margin-bottom: 2rem;
    padding: 1rem;

    @media screen and (min-width: 48rem) {
      align-items: center;
      display: flex;
      width: 100%;
    }

    .c7-message--error {
      background: rgba(255, 255, 255, 0.4);
      border-radius: 0.2em;
      color: $color-alert;
      margin: 0 0 1.5rem;
      text-align: left;
    }
  }
}
