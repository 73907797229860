//c7:account__reservations

//variables
$border-color: #eee !default;
$margin-bottom: 1.5rem !default;
$max-member-width: 50rem !default;

//Default Button
@mixin panel {
  border: 1px solid $border-color;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: $margin-bottom;
  padding: 0.5rem 0.5rem 0;
  position: relative;

  @media screen and (min-width: 40rem) {
    padding: 1.5rem 1.5rem 0;
  }
}

// Reservations List

.c7-account__reservations {
  margin: 0 auto $margin-bottom;
  max-width: $max-member-width;
  width: 100%;

  h2 {
    text-align: center;
  }

  .c7-account__dashboard & {
    margin: 0 auto $margin-bottom * 2;
    padding: 0.5rem 0.5rem 0;
    text-align: left;

    @media screen and (min-width: 25rem) {
      padding: 1.5rem 1.5rem 0;
    }
  }
}
