//c7:cart-items

//variables
$border-color: #eee !default;
$margin-bottom: 1.5rem !default;
$max-width: 50rem !default;
$border-radius: 0 !default;

.c7-cart-items {
  & > div {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: $margin-bottom;
    position: relative;

    @media screen and (min-width: 580px) {
      flex-wrap: nowrap;
    }
  }

  &__image {
    padding-right: 2%;
    width: 12%;

    @media screen and (max-width: 580px) {
      display: none;
    }
  }

  &__title {
    font-weight: bold;
    line-height: 1.1;
    padding-right: 3%;
    width: 32%;

    @media screen and (max-width: 580px) {
      width: 40%;
    }

    &__variant {
      font-size: 0.875em;
      font-style: italic;
      font-weight: normal;
    }

    a {
      display: block;
      font-size: 0.875em;
      font-weight: normal;
      margin-top: 0.5rem;
    }
  }

  &__quantity {
    padding-right: 2%;
    text-align: center;
    width: 16%;

    @media screen and (max-width: 580px) {
      width: 20%;
    }

    form {
      margin: 0;

      button {
        border: 0;

        &:hover {
          border: 0;
        }
      }
    }

    input[type='text'] {
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: $border-radius;
      font-size: 0.75rem;
      height: 36px;
      margin-bottom: 0.25em;
      max-width: 60px;
      min-width: 50px;
      text-align: center;
      width: 100%;
    }

    select {
      background: url('/assets/beta/images/c7-icons/select-background.svg')
        no-repeat center right 10px #f7f4f3;
      background-size: 16px;
      padding-right: 16px;
      width: 60px;
    }
  }

  &__price {
    text-align: right;
    width: 20%;

    span {
      text-decoration: line-through;
    }
  }

  &__item-total {
    text-align: right;
    width: 20%;
  }

  //Order history summary items
  &--order-history {
    div {
      align-items: flex-start;
      font-size: 0.75rem;
    }
  }

  //Club summary items
  &--club-shipment {
    div {
      align-items: flex-start;
      font-size: 0.75rem;
    }
  }
}

.c7-cart__wrapper {
  .c7-cart-items {
    &__quantity {
      @media screen and (max-width: 580px) {
        width: 20%;
      }
    }

    &__image {
      @media screen and (max-width: 580px) {
        display: block;
        width: 15%;
      }
    }

    &__title {
      @media screen and (max-width: 580px) {
        width: 65%;
      }
    }

    &__price {
      @media screen and (max-width: 580px) {
        width: 80%;
      }
    }
  }
}

.c7-account__club-status {
  .c7-cart-items div {
    align-items: flex-start;
    font-size: 0.75rem;
  }
}
