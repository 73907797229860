//variables
$margin-bottom: 1.5rem !default;

.c7-product-specs {
  margin: 0 auto $margin-bottom;
  max-width: 350px;
  text-align: center;

  @media screen and (min-width: 48em) {
    margin: 0 0 $margin-bottom;
    text-align: left;
  }

  h2 {
    text-align: center;

    @media screen and (min-width: 48em) {
      text-align: left;
    }
  }
}

.c7-product-spec {
  font-size: 0.875rem;
  font-weight: bold;
  margin-bottom: $margin-bottom / 2;

  @media screen and (min-width: 48em) {
    display: flex;
  }

  div {
    &:first-child {
      font-weight: normal;
      padding-right: 2%;
    }
  }
}
