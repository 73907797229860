//c7:Club

//variables
$margin-bottom: 1.5rem !default;


//club list
.c7-club-list {
  &__item {
    display: flex;
    flex-direction: column;
    margin-bottom: $margin-bottom * 2;
	
    @media screen and (min-width: 48rem) {
      align-items: center;
      flex-direction: row;
    }
	
    &__image {
      margin-bottom: $margin-bottom;
	
      @media screen and (min-width: 48rem) {
        margin-bottom: 0;
        width: 50%;
      }
    }
	
    &__content {
      padding: 1rem;
	
      @media screen and (min-width: 48rem) {
        margin-bottom: 0;
        padding: 1rem 2rem;
        width: 50%;
      }
	
      .c7-club-join-button {
        @media screen and (min-width: 48rem) {
          text-align: left;
        }
      }
    }
	
    &:nth-child(even) {
      @media screen and (min-width: 48rem) {
        flex-direction: row-reverse;
      }
    }
  }
}


.c7-club {
  background: #fff;
  border-left: 1px solid #ccc;
  box-sizing: border-box;
  height: 100vh;
  max-width: 500px;
  overflow-y: auto;
  padding: 2rem 1.5rem;
  position: fixed;
  right: 0;
  text-align: left;
  top: 0;
  transform: translateX(100%);
  transition: all 0.5s ease-in;
  width: 100%;
  z-index: 899;

  &.c7-visible {
    box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.25);
    transform: translateX(0);

    .c7-cart__close {
      opacity: 1;
    }
  }

  h1 {
    border-bottom: 1px solid #999;
    font-size: 1.125rem;
    margin-bottom: 1rem;
    padding-bottom: 0.65em;
  }

  &__close {
    border: 1px solid #999;
    border-radius: 50%;
    color: #999;
    display: inline-block;
    //opacity: 0;
    height: 32px;
    line-height: 32px;
    position: absolute;
    right: 1.5rem;
    text-align: center;
    top: 1rem;
    transition: all 0.5s ease-in;
    width: 32px;

    &:hover {
      border-color: #111;
      color: #111;
      text-decoration: none;
    }
  }


  &__carrot {
    background: #666;
    border-radius: $border-radius;
    color: #eee;
    font-size: 0.8125rem;
    margin: 2rem auto;
    max-width: 86%;
    padding: 1rem 2rem;
    text-align: center;

    p {
      font-size: 0.8125rem;
      margin: 0;
      padding: 0;
    }
  }

  &__actions {
    align-items: center;
    display: flex;
    font-size: 1rem;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    &--bottom {
      border-bottom: 1px solid #ccc;
      padding-bottom: 1rem;
    }
  }

  .c7-cart-items__title {
    a {
      font-size: 0.875em;
    }
  }

  .c7-cart-items__title__sku {
    display: none;
  }

  .c7-cart-items__title__sku-description {
    display: none;
  }

  .c7-cart-items__title__variant {
    font-size: 0.875em;
    font-style: italic;
  }

  .c7-button-alt-small {
    padding: 0.25em 0.15em;
  }

  &__summary {
    border-top: 1px solid $border-color;
    padding-top: $margin-bottom;

    @media screen and (min-width: 30em) {
      display: flex;
    }

    &__message {
      padding: 1rem;

      p {
        font-size: 0.875rem;
      }

      @media screen and (min-width: 30em) {
        margin-bottom: $margin-bottom;
        padding: 0.5rem 1rem;
        width: 50%;
      }

      a {
        display: inline-block;
        margin-bottom: 0.5rem;
      }

      .c7-form {
        max-height: 0;
        opacity: 0;
        transition: all 300ms ease-out;
        visibility: hidden;
      }

      .c7-visible {
        max-height: 400px;
        opacity: 1;
        visibility: visible;
      }

      ul {
        list-style: none;
        margin: 0 0 1.5rem;
        padding: 0;

        li {
          margin-bottom: 0.5rem;
          position: relative;

          &::before {
            content: '-';
            left: 0;
            position: absolute;
          }

          .c7-discount-title {
            font-style: italic;
          }

          .c7-discount-amount {
            display: block;
            font-weight: bold;
          }
        }
      }

    }

    &__totals {

      @media screen and (min-width: 30em) {
        width: 50%;
      }
    }

  }
}
