#c7-content {
  min-height: 100vh;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  ol,
  p,
  .c7-title {
    margin-top: 0;
  }

  h1 {
    text-align: center;
  }

  &.c7-content--checkout {
    background-color: #fff;
  }

  a {
    text-decoration: none;
    //font-family: $button-font-family;
  }
}
