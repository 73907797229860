//c7:account

//variables
$margin-bottom: 1.5rem !default;
$max-member-width: 50rem !default;
$border-color: #eee !default;

.c7-account-icon {
  vertical-align: text-top;
  width: 1rem;
}

.c7-account-row {
  @include box-sizing;
  margin: 0 auto;
  max-width: $max-member-width;
  text-align: left;

  @media screen and (min-width: 42.5rem) {
    display: flex;
    justify-content: space-between;
    margin-bottom: $margin-bottom * 1.5;
  }

  &--basic {
    display: block;
    margin-bottom: 0;
  }

  &--add {
    justify-content: center;
    margin-bottom: $margin-bottom * 2;
    text-align: center;
  }

  &--delete {
    border-top: 1px dotted #eee;
    justify-content: center;
    margin-bottom: $margin-bottom * 2;
    margin-top: $margin-bottom * 2;
    max-width: $max-form-width;
    padding-top: $margin-bottom * 2;
    text-align: center;
  }

  & > div {
    @include box-sizing;
    color: #888;
    font-size: 0.75rem;
    margin-bottom: $margin-bottom;
    position: relative;
    text-align: left;

    strong {
      color: #000;
      display: inline-block;
      font-size: 1.1667em;
      margin: 0.2rem 0;
      max-width: calc(100% - 60px);
    }

    .c7-button-alt-small {
      font-size: 0.6875rem;
      position: absolute;
      right: 0;
      top: 0;
    }

    @media screen and (min-width: 42.5rem) {
      margin: 0;
      width: 48%;
    }

  }

}

.c7-account-tile {
  @include box-sizing;
  border: 1px solid #ccc;
  border-radius: $border-radius;
  padding: 0.5rem;

  @media screen and (min-width: 25rem) {
    padding: 1.5rem;
  }

  .c7-button-alt-small {
    font-size: 0.6875rem;
    position: absolute;
    right: 0.5rem !important;
    top: 0.5rem !important;

    @media screen and (min-width: 25rem) {
      right: 1.5rem !important;
      top: 1.5rem !important;
    }
  }

  &.c7-error {
    background-color: $message-error-color;
    border-radius: 5px;
    color: #fff;
    padding-bottom: 0.5rem;

    @media screen and (min-width: 25rem) {
      padding-bottom: 1.5rem;
    }

    strong {
      color: #fff;
    }

    .c7-button-alt-small {
      background-color: transparent;
      border-color: #fff;
      color: #fff;
      opacity: 0.75;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.c7-account-tile__section {
  margin-bottom: $margin-bottom;
  position: relative;

  .c7-button-alt-small {
    right: 0 !important;
    top: 0 !important;
  }

  &__club-shipment {
    &__item {
      display: block;
      line-height: 1.1;
      padding: 0.25rem 0 0.25rem 0.5rem;
      position: relative;

      &::before {
        content: '-';
        left: 0;
        position: absolute;
      }
    }
  }
}

// .c7-account-shipment {
// 	max-width: 70rem;
// 	padding: 2rem 0;
// 	margin: 0 auto;

// 	@media screen and (min-width: 40rem) {
// 		//display: flex;
// 		padding: 2rem 1rem;
// 	}

// 	h4 {
// 		text-align: center;
// 	}


// 	&__products {
// 		margin-bottom: 2rem;


// 		.c7-products {

// 			@media screen and (min-width: 40rem) {
// 				display: flex;
// 				padding: 0 2rem;
// 				text-align: left;
// 			}

// 			.c7-product {

// 				@media screen and (min-width: 40rem) {
// 					width: 50%;
// 				}

// 				@media screen and (min-width: 60rem) {
// 					width: 33%;
// 				}

// 				.c7-product__photo {
// 				    border-right: 0;
// 				    padding-right: 1rem;
// 				    width: 25%;
// 				}

// 				.c7-product__title {
// 					font-size: 0.917rem;
// 				}

// 				.c7-product__info {
// 					padding-left: 1rem;
// 				    width: 75%;
// 				}

// 				.c7-form, .c7-add-to-cart {
// 					align-self: flex-start;
// 					margin: 0 0 1.5rem;
// 					padding: 0;

// 					.c7-form__row label {
// 					    width: 100%;
// 					    max-width: 80px;
// 					}

// 					button {
// 						display: none;
// 					}
// 				}
// 			}

// 		}
// 	}

// 	&__addons {
// 		border-bottom: 1px solid #ccc;
// 		border-top: 1px solid #ccc;
// 		margin-top: 2rem;
// 		padding-top: 3rem;
// 		margin-bottom: 2rem;

// 		.c7-products {
// 			margin-bottom: 2rem;

// 			@media screen and (min-width: 40rem) {
// 				display: flex;
// 				padding: 0 2rem;
// 				text-align: left;
// 			}

// 			.c7-product {

// 				@media screen and (min-width: 40rem) {
// 					width: 50%;
// 				}

// 				@media screen and (min-width: 60rem) {
// 					width: 33%;
// 				}

// 				.c7-product__photo {
// 				    border-right: 0;
// 				    padding-right: 1rem;
// 				    width: 25%;
// 				    text-align: center;
// 				}

// 				.c7-product__info {
// 					padding-left: 1rem;
// 				    width: 75%;
// 				}

// 				.c7-product__title {
// 					font-size: 0.875rem;
// 				}

// 				.c7-form, .c7-add-to-cart {
// 					align-self: flex-start;
// 					margin: 0 0 1.5rem;
// 					padding: 0;
// 				}
// 			}

// 		}

// 	}


// }
