//c7:account__create-account
//variables
$margin-bottom: 1.5rem !default;

.c7-account__create-account {
  h2 {
    text-align: center;
  }

}

