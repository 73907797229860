//variables
$margin-bottom: 1.5rem !default;

.c7-product-detail {
  display: block;
  margin: 0 0 3rem;
  width: 100%;

  @media screen and (min-width: 50em) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto 3rem;
    max-width: $max-width;
  }

  &__photo {
    margin: 0 0 1rem;
    padding: 0;
    text-align: center;
    width: 100%;

    @media screen and (min-width: 50em) {
      order: 2;
      text-align: center;
      width: 35%;
    }

    img {
      max-height: 250px;

      @media screen and (min-width: 50em) {
        max-height: 640px;
      }
    }
  }

  &__info {
    text-align: center;
    width: 100%;

    @media screen and (min-width: 50em) {
      text-align: left;
      width: 65%;
    }
  }

  &__titles {
    //font-weight: bold;

    @media screen and (min-width: 50em) {
      font-size: 1.875rem;
      text-align: left !important;
      width: 100%;
    }
  }

  .c7-product__variant {
    margin-bottom: 1rem;

    &__price {
      color: #333;
      display: block;
      font-size: 1.5em;
      margin: 0 0 0.5rem;
      width: 100%;
    }

    &__gift-card {
      padding: 10px 0 5px;

      #c7-recipient,
      #c7-giftMessage {
        max-width: 250px;
        min-width: 120px;
      }

      #c7-giftMessage {
        height: 100px;
      }
    }
  }

  h3,
  h2 {
    // font-size: 1rem;
    // color: #333;
  }

  .c7-product-spec {
    div {
      color: #333;
      display: inline-block;
      font-size: 1.125rem;

      &:first-child {
        padding-right: 0.25em;
        text-transform: uppercase;

        &::after {
          content: ':';
        }
      }

      & + div {
        font-style: italic;
        font-weight: bold;
      }
    }
  }
}
