//c7:account__reservation-history

//variables
$border-color: #eee !default;
$margin-bottom: 1.5rem !default;
$max-member-width: 50rem !default;

//Default Button
@mixin panel {
  border: 1px solid $border-color;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: $margin-bottom;
  padding: 0.5rem 0.5rem 0;
  position: relative;

  @media screen and (min-width: 40rem) {
    padding: 1.5rem 1.5rem 0;
  }
}

// Order History List

.c7-account__reservation-history {
  margin: 0 auto $margin-bottom;
  max-width: $max-member-width;
  width: 100%;

  h2 {
    text-align: center;
  }

  .c7-account__dashboard & {
    margin: 0 auto $margin-bottom * 2;
    padding: 0.5rem 0.5rem 0;
    text-align: left;

    @media screen and (min-width: 25rem) {
      padding: 1.5rem 1.5rem 0;
    }
  }
}

.c7-account__reservation {
  border-bottom: 1px solid $border-color;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: $margin-bottom * 2;
  padding-bottom: $margin-bottom;
  text-align: left;
  //font-size: 0.875rem;

  .c7-account__dashboard & {
    margin-bottom: $margin-bottom;
  }

  .c7-button-alt-small {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  div {
    font-size: 0.875em;

    @media screen and (max-width: 40rem) {
      margin-bottom: $margin-bottom;
      width: 50%;
    }

    &:first-child {
      display: flex;
      font-size: 1rem;
      justify-content: space-between;
      margin-bottom: 0.5rem;
      width: 100%;
    }

    &:nth-child(2) {
      @media screen and (min-width: 40rem) {
        width: 16%;
      }
    }

    &:nth-child(3) {
      @media screen and (min-width: 40rem) {
        width: 30%;
      }
    }

    &:nth-child(4) {
      @media screen and (min-width: 40rem) {
        width: 30%;
      }
    }

    &:nth-child(5) {
      @media screen and (min-width: 40rem) {
        width: 12%;
      }
    }

    &:last-child {
      @media screen and (min-width: 40rem) {
        width: 12%;
      }
    }
  }
}

.c7-account__order-detail {
  @media screen and (min-width: 48rem) {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto 3rem;
    max-width: $max-width;
  }

  h2 {
    margin-bottom: 1em;
    width: 100%;

    @media screen and (min-width: 48rem) {
      padding-left: 60px;
    }

    span {
      display: block;
      font-size: 0.65em;
    }
  }

  h4 {
    margin-bottom: 0.5rem;

    @media screen and (min-width: 25rem) {
      margin-bottom: 1rem;
    }
  }

  &__customer-details {
    margin-bottom: $margin-bottom * 2;
    position: relative;

    @media screen and (min-width: 48rem) {
      order: 1;
      padding-left: 60px;
      width: 60%;

      &::before {
        background: url('/assets/beta/images/c7-icons/icon-checkmark-circle-red.svg')
          no-repeat center center;
        background-size: 40px;
        content: '';
        height: 48px;
        left: 0;
        position: absolute;
        top: 0;
        width: 48px;
      }
    }
  }

  .c7-checkout-summary {
    @include panel();
    padding: 1.5rem 1.5rem 1rem;

    @media screen and (min-width: 48rem) {
      display: block;
      order: 2;
      padding: 1rem 1rem 0;
      width: 35%;
    }

    .c7-cart-items__quantity {
      width: 8%;
    }

    .c7-cart-items__title {
      padding-right: 2%;
      width: 40%;
    }
  }

  .c7-checkout-template__toggle {
    @include panel();
    padding: 0.5rem 0.5rem 0.25rem;

    @media screen and (min-width: 40rem) {
      padding: 0.5rem 0.5rem 0.25rem;
    }

    @media screen and (min-width: 48rem) {
      display: none;
    }

    & + .c7-checkout-summary {
      @media screen and (max-width: 48rem) {
        border-top: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        box-sizing: border-box;
        margin-top: -1.75rem;
      }

      @media screen and (min-width: 62rem) {
        min-height: auto;
      }
    }
  }
}

// .c7-order__progress {
//   display: flex;
//   justify-content: space-between;
//   position: relative;
//   padding: 0.5rem 0.5rem 0;
//
//   @media screen and (min-width: 40rem) {
//     padding: 1.5rem 1.5rem 0;
//   }
//
//   &:before {
//     position: absolute;
//     content: "";
//     left: 5%;
//     top: 30%;
//     width: 0;
//     border: 1px solid #990000;
//     z-index: -1;
//   }
//
//   &--50 {
//     &:before {
//       width: 30%;
//     }
//   }
//
//   &--75 {
//     &:before {
//       width: 60%;
//     }
//   }
//
//   &--100 {
//     &:before {
//       width: 90%;
//     }
//   }
//
//   div {
//     background-repeat: no-repeat;
//     background-position: top center;
//     background-size: 2rem;
//     background-color: #fff;
//     padding-top: 3rem;
//     text-align: center;
//     font-size: 0.75rem;
//     margin-bottom: 1.5rem;
//     opacity: 0.45;
//
//     strong {
//       display: block;
//       font-weight: normal;
//     }
//
//     &.complete {
//       opacity: 1;
//
//       strong {
//         color: #990000;
//         font-weight: bold;
//       }
//     }
//
//     &:first-child {
//       background-image: url(/assets/beta/images/c7-icons/icon-check.svg);
//
//       &.complete {
//         background-image: url(/assets/beta/images/c7-icons/icon-check-red.svg);
//       }
//     }
//
//     &:nth-child(2) {
//       background-image: url(/assets/beta/images/c7-icons/icon-truck.svg);
//
//       &.complete {
//         background-image: url(/assets/beta/images/c7-icons/icon-truck-red.svg);
//       }
//     }
//
//     &:nth-child(3) {
//       background-image: url(/assets/beta/images/c7-icons/icon-mailbox.svg);
//
//       &.complete {
//         background-image: url(/assets/beta/images/c7-icons/icon-mailbox-red.svg);
//       }
//     }
//
//     &:nth-child(4) {
//       background-image: url(/assets/beta/images/c7-icons/icon-home.svg);
//
//       &.complete {
//         background-image: url(/assets/beta/images/c7-icons/icon-home-red.svg);
//       }
//     }
//   }
// }

// .c7-order__map {
//   position: relative;
//   padding-bottom: 50%;
//   height: 0;
//   margin: 0 -0.5rem $margin-bottom * 1.5;
//
//   @media screen and (min-width: 40rem) {
//     margin: 0 -1.5rem $margin-bottom * 1.5;
//   }
//
//   iframe {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     border: 0;
//   }
// }

// .c7-order__shipping {
//   @include panel();
//   padding-top: 0;
//
//   @media screen and (min-width: 40rem) {
//     padding: 0 1.5rem 0;
//   }
//
//   hr {
//     margin: $margin-bottom auto;
//     border: solid $border-color;
//     border-width: 1px 0 0;
//     clear: both;
//   }
// }
//
.c7-order__shipping-updates {
  @include panel();
}

.c7-order__customer-detail {
  @include panel();

  div {
    margin-bottom: $margin-bottom;
    position: relative;

    @media screen and (min-width: 42.5rem) {
      display: flex;
      justify-content: space-between;
    }

    strong {
      color: #000;
    }

    span {
      display: inline-block;
      width: 100%;

      @media screen and (min-width: 42.5rem) {
        width: 48%;
      }

      & + span {
        margin-top: $margin-bottom;

        @media screen and (min-width: 42.5rem) {
          margin-top: 0;
        }
      }
    }
  }
}

.c7-order__buttons {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.c7-order-summary-wrapper {
  @include box-sizing;
  display: none;
  padding: 1.5rem;
  position: relative;
  transform: none;
  width: 100%;

  &.c7-visible {
    display: block;
  }

  @media screen and (min-width: 48rem) {
    display: block;
  }
}
