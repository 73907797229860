//variables
$text-color: #121212 !default;

$color-warning: #edd06a !default;
$color-alert: #930 !default;
$color-error: #930 !default;
$color-notification: #69c !default;
$color-success: #71a13f !default;

$link-color: #333;

$message-warning-color: $color-warning !default;
$message-alert-color: $color-alert !default;
$message-error-color: $color-error !default;
$message-notification-color: $color-notification !default;
$message-success-color: $color-success !default;

$border-color: #ccc !default;
$margin-bottom: 1.5rem !default;
$max-width: 64rem !default;
$max-member-width: 50rem !default;
$max-form-width: 36rem !default;

//form
$field-font-family: Frutiger, 'Frutiger Linotype', Univers, Calibri, Tahoma,
  Geneva, Arial, sans-serif !default;
$field-font-size: 0.875rem;

//buttons
$button-font-family: Frutiger, 'Frutiger Linotype', Univers, Calibri, Tahoma,
  Geneva, Arial, sans-serif;
$button-font-size: 1rem;

//button color
$button-color: #fff;
$button-background: #333;
$button-outline: #333;

$button-color-hover: #fff;
$button-background-hover: #000;
$button-outline-hover: #000;

//button alt color
$button-alt-color: #999;
$button-alt-background: transparent;
$button-alt-outline: #ccc;

$button-alt-color-hover: #333;
$button-alt-background-hover: transparent;
$button-alt-outline-hover: #999;

$border-radius: 0;

//helpers
@mixin box-sizing {
  box-sizing: border-box;
}
