.c7-checkout-pickup-locations {
  flex-wrap: wrap;

  @media screen and (min-width: 26.25rem) {
    display: flex;
    flex-wrap: nowrap;
  }

  &__address {
    font-weight: normal;
    margin-bottom: 1rem;
    width: calc(100% - 20px);

    @media screen and (min-width: 26.25rem) {
      margin-bottom: 0;
      width: 50%;
    }
  }

  &__hours {
    font-weight: normal;
    margin-left: 20px;

    @media screen and (min-width: 26.25rem) {
      margin-left: 0;
      width: calc(50% - 20px);
    }
  }
}
