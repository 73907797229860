//c7:cart-summary

//variables
$border-color: #eee !default;
$margin-bottom: 1.5rem !default;
$max-width: 50rem !default;

.c7-cart-summary {
  @include box-sizing;
  margin-bottom: $margin-bottom;

  & > div {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: $margin-bottom / 2;
    padding-top: $margin-bottom / 2;
    position: relative;

    div {
      text-align: right;
      width: 60%;

      @media screen and (min-width: 30em) {
        width: 60%;
      }

      & + div {
        width: 40%;

        @media screen and (min-width: 30em) {
          width: 40%;
        }
      }
    }
  }

  &__subtotal {
    border-top: 1px solid $border-color;
  }

  &__total {
    border-top: 1px solid $border-color;
    padding-top: $margin-bottom;

    div:last-child {
      font-size: 1.25rem;

      span {
        font-size: 0.875rem;
      }
    }
  }

  &__promotion-message {
    //color: red;
  }
}

//no lines on the cart page
.c7-cart__summary {
  .c7-cart-summary__subtotal {
    border: 0;
  }

  .c7-cart-summary__total {
    border: 0;
  }
}

.c7-account__order-detail__order-details,
.c7-account__club-status,
.c7-club-shipment {
  .c7-order-summary {
    padding: 1rem;

    .c7-cart-summary {
      margin-bottom: 0;
    }
  }

  .c7-cart-summary {
    & > div {
      font-size: 0.75rem;

      div {
        text-align: left;

        & + div {
          text-align: right;
        }
      }
    }
  }
}
