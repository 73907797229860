//c7:account__credit-cards

//variables
$margin-bottom: 1.5rem !default;

.c7-account__credit-cards {
  h2 {
    text-align: center;
  }

  &__visa {
    background: url('/assets/beta/images/credit-cards/visa.svg') top left
      no-repeat;
    background-size: contain;
    float: left;
    height: 40px;
    margin: 0 0.5rem 0.5rem 0;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    width: 50px !important;
  }

  &__mastercard {
    background: url('/assets/beta/images/credit-cards/mastercard.svg') top left
      no-repeat;
    background-size: contain;
    float: left;
    height: 40px;
    margin: 0 0.5rem 0.5rem 0;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    width: 50px !important;
  }

  &__amex {
    background: url('/assets/beta/images/credit-cards/amex.svg') top left
      no-repeat;
    background-size: contain;
    float: left;
    height: 40px;
    margin: 0 0.5rem 0.5rem 0;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    width: 50px !important;
  }

  &__discover {
    background: url('/assets/beta/images/credit-cards/discover.svg') top left
      no-repeat;
    background-size: contain;
    float: left;
    height: 40px;
    margin: 0 0.5rem 0.5rem 0;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    width: 50px !important;
  }

  &__dinersclub {
    background: url('/assets/beta/images/credit-cards/dinersclub.svg') top left
      no-repeat;
    background-size: contain;
    float: left;
    height: 40px;
    margin: 0 0.5rem 0.5rem 0;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    width: 50px !important;
  }

  &__jcb {
    background: url('/assets/beta/images/credit-cards/jcb.svg') top left
      no-repeat;
    background-size: contain;
    float: left;
    height: 40px;
    margin: 0 0.5rem 0.5rem 0;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    width: 50px !important;
  }
}
