//c7-modal
.c7-modal {
  height: 100vh;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all 0.2s ease-in;
  visibility: hidden;
  width: 100%;
  z-index: 999;

  &::before {
    background: rgba(255, 255, 255, 0.8);
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  &__content {
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.3);
    left: 50%;
    max-height: 100%;
    overflow-y: auto;
    padding: 2rem 1rem;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    &--add-hold {
      min-height: 400px;
    }

    @media screen and (min-width: 48em) {
      max-width: 40rem;
      padding: 2rem;
      width: 90%;
    }

    ul {
      column-count: 2;
      list-style: none;
      margin: 0;
      padding: 0;
      text-align: left;

      @media only screen and (min-width: 40em) {
        column-count: 3;
      }

      li {
        padding: 0.35rem 0;
      }
    }

    .c7-account-login {
      display: block;
      margin: 0 0 3rem;
      width: 100%;

      .c7-form label {
        @media only screen and (min-width: 40em) {
          max-width: 20rem;
        }
      }

      .c7-account-login--returning-customer {
        width: 100%;
      }
    }

    .c7-checkout__delivery-date-picker {
      width: 100%;

      .c7-search-tools--Date {
        width: 100%;

        .rdt {
          margin: auto;
        }
      }
    }
  }

  &__close {
    border: 1px solid rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    color: rgba(0, 0, 0, 0.6);
    display: inline-block;
    height: 32px;
    line-height: 32px;
    position: absolute;
    right: 1rem;
    text-align: center;
    top: 1rem;
    transition: all 300ms ease-in;
    width: 32px;

    &:hover {
      border: 1px solid rgba(0, 0, 0, 1);
      color: rgba(0, 0, 0, 1);
    }
  }

  &.c7-visible {
    opacity: 1;
    visibility: visible;
  }
}

.c7-modal--wide {
  .c7-modal__content {
    max-width: 55rem;
  }
}

.c7-modal--full {
  .c7-modal__content {
    max-width: 70rem;
  }
}
