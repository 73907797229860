//c7:tools
.c7-tools {
  align-items: center;
  background: #333;
  display: flex;
  font-size: 0.875em;
  justify-content: space-between;
  padding: 0.75rem 1em;
  position: relative;
  z-index: 900;

  @media screen and (min-width: 40rem) {
    background: none;
    color: #333;
    position: absolute;
    right: 1rem;
    text-align: right;
    top: 1.5rem;
  }
}

#c7-login {
  display: inline-block;
  white-space: nowrap;

  @media screen and (min-width: 40rem) {
    padding: 0 1rem 0 0;
  }

  & > a {
    color: #fff;
    position: relative;
    text-decoration: none;

    @media screen and (min-width: 40rem) {
      color: #333;

      &:hover {
        color: #000;
        text-decoration: underline;
      }
    }
  }

  .c7-login__logged-in {
    &::after {
      content: ',';
    }
  }

  .c7-login__with-alert {
    margin-right: 0.5rem;
  }

  .c7-login__alert {
    background-color: #000;
    border-radius: 50%;
    color: #fff;
    display: block;
    height: 14px;
    position: absolute;
    right: -6px;
    text-align: center;
    top: -8px;
    width: 14px;
  }
}

#c7-club {
  display: inline-block;
  white-space: nowrap;

  @media screen and (min-width: 40rem) {
    padding: 0 1rem 0 0;
  }

  & > a {
    color: #fff;
    text-decoration: none;

    &::before {
      content: 'Club Shipment';
    }

    @media screen and (min-width: 40rem) {
      color: #333;

      &:hover {
        color: #000;
        text-decoration: underline;
      }
    }
  }
}

#c7-cart {
  display: inline-block;

  @media screen and (min-width: 40rem) {
    color: #333;
  }

  & > a {
    color: #fff;
    text-decoration: none;
    white-space: nowrap;

    &::before {
      content: 'Cart';
    }

    @media screen and (min-width: 40rem) {
      color: #333;

      &:hover {
        color: #000;
        text-decoration: underline;
      }
    }
  }
}
