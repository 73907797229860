//c7:checkout

//variables
$border-color: #eee !default;
$margin-bottom: 1.5rem !default;
$max-width: 50rem !default;

#c7-checkout-content {
  background-color: #fff;
}

.c7-checkout-template {
  height: 100%;
  min-height: 100%;

  &__logo {
    margin: 2em auto;
    max-width: 80%;
    padding: 0.5rem 1rem;
    width: 200px;

    @media screen and (min-width: 62rem) {
      display: none;
    }
  }

  &__toggle {
    background: #faf8f5;
    border-bottom: 1px solid #e8e4de;
    border-top: 1px solid #e8e4de;
    cursor: pointer;
    padding: 1rem;

    @media screen and (min-width: 62rem) {
      display: none;
    }

    div {
      align-items: center;
      background: url('/assets/beta/images/c7-icons/cart.svg') no-repeat center
        left 2px;
      background-size: 24px;
      display: flex;
      height: 32px;
      justify-content: space-between;
      line-height: 32px;
      margin: 0 auto;
      max-width: 36rem;
      padding-left: 32px;
    }

    span {
      &:first-child::after {
        background: url('/assets/beta/images/c7-icons/icon-toggle.svg') center
          center no-repeat;
        content: '';
        display: inline-block;
        height: 10px;
        width: 20px;

        @media screen and (min-width: 62rem) {
          display: none;
        }
      }
    }
  }
}

.c7-checkout {
  min-height: 100%;

  @media screen and (min-width: 62rem) {
    align-items: stretch;
    display: flex;
    justify-content: space-between;
    padding-top: 0;
    width: 100%;
  }

  &__main {
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 100%;
    padding: 3rem 1rem 1rem;
    width: 37rem;

    .c7-account__order-detail__customer-details {
      width: 100%;

      @media screen and (min-width: 62rem) {
        width: 90%;
      }
    }

    &--wide {
      width: 56rem;
    }
  }

  &__header {
    &__logo {
      display: none;
      margin: 0 auto 2rem;
      max-width: 80%;
      padding: 0.5rem 1rem;
      width: 200px;

      @media screen and (min-width: 62rem) {
        display: block;
      }
    }

    h2 {
      margin-bottom: 1em;
      width: 100%;

      @media screen and (min-width: 48rem) {
        padding-left: 60px;
      }

      span {
        display: block;
        font-size: 0.65em;
      }

      & + div {
        margin-bottom: 2em;
        margin-top: -1rem;
        width: 100%;

        @media screen and (min-width: 48rem) {
          padding-left: 60px;
        }
      }
    }

    p {
      margin-bottom: 1rem;
    }
  }

  &__content {
    h3 {
      margin-bottom: 1.25rem;

      & + p {
        margin-top: -1.25rem;
      }
    }

    &__title {
      @media screen and (min-width: 30rem) {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }

      h3 {
        margin: 0;
      }

      a {
        font-size: 0.75rem;
        text-align: right;
      }
    }

    &__account {
      align-items: center;
      display: flex;

      &__avatar {
        margin-right: 1rem;
      }

      .c7-avatar {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 5px;
        height: 3.5rem;
        width: 3.5rem;
      }
    }

    &__buttons {
      @media screen and (max-width: 45.99rem) {
        text-align: center !important;
      }

      @media screen and (min-width: 46rem) {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }

      button {
        @media screen and (max-width: 45.99rem) {
          margin-bottom: 1rem;
          width: 100%;
        }

        @media screen and (min-width: 46rem) {
          order: 2;
        }
      }

      a {
        &::before {
          content: '\2039\00a0\00a0';
        }
      }
    }

    &__radio-options-group {
      border: 1px solid $border-color;
      border-radius: 5px;
      padding: 0 !important;

      & > div {
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        padding: 1rem;
        width: 100%;

        &:last-child {
          border: 0;
        }

        & + div {
          padding: 1rem;
        }
      }

      .c7-radio,
      .radio {
        input[type='radio'],
        input[type='checkbox'] {
          margin-top: 0.2rem;
        }

        &__right {
          font-weight: bold;
          margin-left: auto;

          &__promo {
            display: inline-block !important;
            font-size: 0.875rem !important;
            padding-right: 1rem;
            text-decoration: line-through;
          }
        }

        &__action-message {
          font-size: 0.875rem !important;
          font-weight: bold;
        }
      }

      label {
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
      }

      &__edit {
        background: #f7f4f3;

        select,
        input[type='text'],
        input[type='email'],
        input[type='tel'],
        input[type='password'],
        input[type='username'] {
          background: #fff;
        }

        & > div {
          margin-bottom: 0.75rem;
        }

        label {
          display: block;
        }
      }

      &__hidden {
        height: 0;
        opacity: 0;
        padding: 0 !important;
        visibility: hidden;
      }
    }

    &__delivery-date-estimate {
      color: #737373;
      font-size: 13px;
      margin-top: 0.25rem;
      padding-left: 20px;

      a {
        font-size: 12px;
      }
    }

    &__summary {
      background: #f7f4f3;
      border: 1px solid rgba(0, 0, 0, 0.3);
      border-radius: 5px;
      margin-bottom: $margin-bottom * 2;
      padding: 1rem;
      text-align: left;

      & > div {
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        display: flex;
        font-size: 0.8125rem;
        justify-content: space-between;
        margin-bottom: 1rem;
        padding-bottom: 1rem;

        div {
          color: #737373;
          padding-right: 0.5rem;
          width: 90%;

          @media screen and (min-width: 640px) {
            display: flex;
            flex-wrap: wrap;
          }

          div {
            @media screen and (min-width: 640px) {
              width: 65%;
            }

            &:first-child:not(.shipping-method-row) {
              color: #000;
              font-weight: bold;

              @media screen and (min-width: 640px) {
                width: 35%;
              }
            }
          }

          .shipping-method-row {
            width: 100%;
          }

          &:last-item {
            font-size: 0.5rem;
            max-width: 10rem;
            padding-right: 0;
            width: 10%;
          }
        }

        &:last-child {
          border: 0;
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }

      &__shipping-price {
        color: #000;
        display: inline-block;
        font-weight: bold;
        margin: 0 0.5em;

        span {
          display: inline-block;
          margin: 0 0.5em 0 0;
          text-decoration: line-through;
        }
      }
    }
  }

  &__cool-pack {
    background: #f7f4f3;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    margin-bottom: $margin-bottom * 2;
    padding: 1rem;
    text-align: left;

    button {
      display: block;
      margin: 1rem 0 0;
    }
  }

  &__footer {
    border-top: 1px solid $border-color;
    margin-top: 1rem;
    padding-top: 2rem;
    text-align: left;

    ul {
      font-size: 0.75rem;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        margin: 0 0 1rem;
        padding: 0;

        &:last-item {
          margin-right: 0;
        }

        a {
          padding: 0.5rem;
        }
      }
    }
  }
}

.c7-no-border {
  border: 0 !important;
}

.c7-prop65 {
  padding-top: $margin-bottom;
}
