.c7-reservation-widget {
  .c7-form {
    max-width: 40rem;
    text-align: center;
  }

  .c7-form__group {
    justify-content: center;

    div {
      @media screen and (min-width: 46rem) {
        margin: 0 1% 0 0;
        padding: 0;
        width: auto;
      }

      &:last-item {
        margin-right: 0;
      }

      &.c7-form-label__reservation-date,
      &.c7-search-tools--Date {
        @media screen and (min-width: 46rem) {
          flex: 0 0 auto;
          width: 200px;
        }
      }

      &.c7-form-label__reservation-guests {
        @media screen and (min-width: 46rem) {
          flex: 0 0 auto;
          width: 100px;
        }
      }

      &.c7-form-label__reservation-time {
        @media screen and (min-width: 46rem) {
          flex: 0 0 auto;
          width: 130px;
        }
      }
    }

    .c7-selectbox + .c7-selectbox {
      margin-left: 0;
    }
  }
}

label[for='c7-reservation-option'] {
  display: block;
  margin-bottom: 0.5rem;
  width: 100%;

  .c7-product__variant__select {
    max-width: 360px;
    width: 100%;
  }
}

.c7-experiences {
  &--no-results {
    margin: 2.5rem auto;
    text-align: center;

    a {
      text-decoration: none;
    }

    .c7-experiences__message {
      display: block;
      font-weight: bold;
      text-align: center;
    }
  }

  &__footer {
    text-align: center;
  }
}

.c7-experience {
  margin-bottom: 1.5rem;

  @media screen and (min-width: 48rem) {
    align-items: flex-start;
    display: flex;
  }

  &__image {
    @media screen and (min-width: 48rem) {
      width: 40%;
    }

    img {
      max-width: 100%;
    }

    & + .c7-experience__content {
      @media screen and (min-width: 48rem) {
        text-align: left;
        width: 60%;
      }

      .c7-form {
        @media screen and (min-width: 48rem) {
          margin: 0 0 auto;
          max-width: 40rem;
        }
      }

      .c7-product__variant__add-to-cart {
        margin: 0 0 1.5rem;
        text-align: left;
      }
    }
  }

  &__content {
    padding: 0.5rem 1rem 0;

    @media screen and (min-width: 48rem) {
      padding: 0.5rem 1.5rem 0;
      text-align: center;
      width: 100%;
    }

    .c7-product__variant__price {
      font-size: 1rem;
      font-weight: bold;
    }

    .c7-form {
      margin: 0 0 1.5rem;
      margin: 0 auto;
      max-width: 36rem;
    }

    .c7-selectboxWrap {
      display: block;
      width: 300px;
    }

    .c7-product__variant__add-to-cart {
      display: block;
      margin: 0 auto;
      text-align: center;
    }

    button {
      margin: 0.25rem 0;
      max-width: 360px;
      width: 100%;

      @media screen and (min-width: 48rem) {
        margin: 0.25rem 0.5rem 0.25rem 0;
        width: auto;
      }
    }
  }

  &__date-nav {
    text-align: center;

    @media screen and (min-width: 48rem) {
      text-align: left;
    }

    a {
      display: inline-block;
      text-decoration: none;

      &:first-child {
        margin-right: 1rem;
      }
    }
  }
}
