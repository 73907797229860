//c7:account__login

//variables
$margin-bottom: 1.5rem !default;
$max-member-width: 50rem !default;

.c7-account-login {
  margin: 0 auto ($margin-bottom * 2);
  max-width: $max-member-width;

  @media screen and (min-width: 48rem) {
    display: flex;
    justify-content: space-around;
  }

  &--returning-customer {
    @media screen and (min-width: 48rem) {
      width: 40%;
    }
  }


  &--new {
    @media screen and (min-width: 48rem) {
      width: 40%;
    }
  }

  h4 {
    margin-bottom: 0.5em;
  }
}

.c7-logout-message {
  p {
    text-align: center;
  }
}

.c7-divider-or {
  display: inline-block;
  margin-bottom: 1rem;
}
