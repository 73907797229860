.c7-loader {
  animation: spin 2s linear infinite;
  border: 2px solid #f3f3f3; /* Light grey */
  border-radius: 50%;
  border-top: 2px solid #3498db; /* Blue */
  display: inline-block;
  height: 16px;
  vertical-align: text-top;
  width: 16px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
