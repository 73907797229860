//variables
$margin-bottom: 1.5rem !default;

.c7-product-quickorder {
  display: block;
  width: 100%;

  &__row {
    align-items: center;
    border-bottom: 1px solid #eee;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;

    @media screen and (min-width: 768px) {
      margin-bottom: 0;
    }
        
    &:last-child {
      border: 0;
    }

    a {
      color: #24374f;
      text-decoration: none;

      &:hover {
        color: #000;
      }
    }

    & > div {
      padding: 0 0.25rem;

      @media screen and (min-width: 768px) {
        padding: 0.5rem 0.25rem;
      }
             
      form {
        margin-bottom: 0;
      }

      &:first-child {
        text-align: left;
        width: 95px;
      }

      &:nth-child(2) {
        text-align: left;
        width: calc(100% - 95px);

        @media screen and (min-width: 768px) {
          width: calc(70% - 95px);
        }
      }

      &:nth-child(3) {
        margin-left: 40%;
        text-align: left;
        width: 30%;

        @media screen and (min-width: 360px) {
          margin-left: 60%;
          width: 20%;
        }
                    
        @media screen and (min-width: 768px) {
          margin-left: 0;
          text-align: right;
          width: 15%;
        }
      }

      &:nth-child(4) {
        text-align: right;
        width: 30%;
                 
        @media screen and (min-width: 360px) {
          width: 20%;
        }

        @media screen and (min-width: 768px) {
          width: 15%;
        }
      }
    }
  }

  &__summary {
    border-top: 1px solid #ccc;
    margin: 0 0 3rem;
    padding: 1rem 0;
    text-align: right;

    & > span {
      display: inline-block;
      padding: 0 0 1rem;
    }
        
    &__button {
      text-align: right;
      width: 100%;
    }

    .c7-cart__total {
      font-weight: bold;
    }
  }
}
