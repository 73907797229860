.c7-allocation {
  margin-bottom: 1rem;
  max-width: 350px;
  width: 100%;

  div {
    span {
      //width: 120px;
      display: inline-block;
      font-weight: normal;
      white-space: nowrap;
    }
  }

  &__title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    font-weight: bold;
    padding: 5px;
  }

  &__quantity {
    font-weight: bold;
    padding: 3px 5px 0;
  }

  &__additional {
    font-weight: bold;
    padding: 3px 5px 0;
  }

  &__wish {
    font-weight: bold;
    padding: 3px 5px 0;
  }

  &__prev {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    font-weight: bold;
    padding: 3px 5px;
  }

  &__request {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: normal;
    opacity: 0.7;
    padding: 3px 5px;
  }
}

.c7-form--allocation-request {
  margin: 0;
  max-width: 200px;
  width: 100%;
}
