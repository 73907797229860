//variables
$field-font-family: Frutiger, 'Frutiger Linotype', Univers, Calibri, Tahoma,
  Geneva, Arial, sans-serif !default;
$field-font-size: 0.875rem;

$margin-bottom: 1.5rem !default;
$border-radius: 0 !default;

//Custom Forms
.c7-form-wrapper {
  min-height: 600px;
  transition: all 300ms ease-out;

  .c7-message {
    margin: 0 auto 1.5rem;
    max-width: 36rem;
  }
}

//Form Globals
.c7-form {
  margin: 0 auto $margin-bottom;
  max-width: $max-form-width;

  label,
  .label {
    display: inline-block;
    font-family: $field-font-family;
    vertical-align: top;
    width: 100%;
  }

  //Form types
  input[type='text'],
  input[type='email'],
  input[type='tel'],
  input[type='password'],
  input[type='username'] {
    @include box-sizing;
    width: 100%;
  }

  select,
  input[type='text'],
  input[type='email'],
  input[type='tel'],
  input[type='password'],
  input[type='username'],
  .c7-input-sudo {
    @include box-sizing;
    background: #f8f8f8;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: $border-radius;
    color: #111;
    font-family: $field-font-family;
    font-size: $field-font-size;
    height: 40px;
    padding: 0.75em;
    text-indent: 0.01px;
    width: 100%;

    a {
      color: #333;

      &:hover {
        text-decoration: none;
      }
    }

    &:focus {
      border: 1px solid rgba(0, 0, 0, 0.6);
    }
  }

  input,
  select {
    box-shadow: none;

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  textarea {
    @include box-sizing;
    appearance: none;
    background: #f8f8f8;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: $border-radius;
    color: #111;
    font-family: $field-font-family;
    font-size: $field-font-size;
    padding: 0.5em;
    width: 100%;

    &:focus {
      border: 1px solid rgba(0, 0, 0, 0.6);
    }
  }

  input[type='radio'],
  input[type='checkbox'] {
    margin-right: 0.5rem;
  }

  select {
    appearance: none;
    cursor: pointer;
    display: block;
    line-height: 1.5;
    margin: 0;
    min-width: 75px;
    padding: 0 0.5rem;
    position: relative;
    width: 100%;
  }

  .c7-selectbox {
    position: relative;
  }

  .c7-radio,
  .c7-checkbox {
    div {
      display: inline-block;
      vertical-align: top;

      span {
        display: block;
        font-size: 0.75em;
      }
    }
  }

  &__button-row {
    display: flex;
    justify-content: space-between;
  }

  //Structure Elements
  & > div {
    margin-bottom: $margin-bottom;
    position: relative;
    text-align: left;

    .c7-button-alt-small {
      position: absolute;
      right: 0.4rem;
      top: 1.55rem;
    }
  }

  &__group {
    @media screen and (min-width: 46rem) {
      display: flex;
    }

    div {
      margin-bottom: 1.25rem;

      @media screen and (min-width: 46rem) {
        margin: 0;
        width: 50%;
      }

      &:last-child {
        margin-bottom: 0;
      }

      label.c7-small {
        font-size: 0.75em;
      }

      div.date-picker,
      div.rdtPicker,
      div.rdtDays {
        @media screen and (min-width: 46rem) {
          margin: 0;
          width: 100%;
        }
      }
    }

    div + div {
      @media screen and (min-width: 46rem) {
        margin-bottom: 0;
        padding-left: 2%;
      }
    }

    input + button {
      margin-top: 0.5rem;
      @media screen and (min-width: 46rem) {
        margin-left: 2%;
        margin-top: 0;
      }
    }

    &--3 {
      div {
        margin-bottom: 1.25rem;

        @media screen and (min-width: 46rem) {
          margin-bottom: 0;
          width: 33.33%;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .c7-coupon-form & {
      margin-bottom: 0.5rem;
    }
  }

  &__group--calendar {
    @media screen and (min-width: 46rem) {
      align-items: flex-start;

      & > div {
        & + div {
          margin-left: 3%;
          width: 47%;
        }
      }
    }
  }

  &__button-row {
    @media screen and (min-width: 46rem) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__question {
    height: 20px;
    margin-top: 9px;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);

    svg {
      fill: rgb(125, 121, 119);
    }

    &:hover ~ .c7-form__tooltip {
      opacity: 1;
      transform: scale(1);
    }
  }

  &__tooltip {
    background: rgb(55, 47, 42);
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    opacity: 0;
    padding: 20px;
    pointer-events: none;
    position: absolute;
    position: absolute;
    right: 5px;
    top: -100%;
    top: -65%;
    transform: scale(0.95);
    transition: all 0.2s ease;
    white-space: nowrap;

    span {
      color: #fff;
      display: inline;
      font-size: 1em;
      margin: 0;
      padding: 0;
    }

    &::before {
      border: solid;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #372f2a;
      bottom: -8px;
      content: '';
      position: absolute;
      right: 12px;
      z-index: 99;
    }
  }

  //Credit Card
  input.mastercard {
    background-image: url('/assets/beta/images/credit-cards/mastercard.svg');
    background-position: center right 0.3rem;
    background-repeat: no-repeat;
    background-size: auto 86%;
  }

  input.visa {
    background-image: url('/assets/beta/images/credit-cards/visa.svg');
    background-position: center right 0.3rem;
    background-repeat: no-repeat;
    background-size: auto 86%;
  }

  input.amex {
    background-image: url('/assets/beta/images/credit-cards/amex.svg');
    background-position: center right 0.3rem;
    background-repeat: no-repeat;
    background-size: auto 86%;
  }

  input.discover {
    background-image: url('/assets/beta/images/credit-cards/discover.svg');
    background-position: center right 0.3rem;
    background-repeat: no-repeat;
    background-size: auto 86%;
  }

  input.dinersclub {
    background-image: url('/assets/beta/images/credit-cards/dinersclub.svg');
    background-position: center right 0.3rem;
    background-repeat: no-repeat;
    background-size: auto 86%;
  }

  input.jcb {
    background-image: url('/assets/beta/images/credit-cards/jcb.svg');
    background-position: center right 0.3rem;
    background-repeat: no-repeat;
    background-size: auto 86%;
  }
}

//select wrapper
.c7-selectboxWrap {
  display: inline-block;
  min-width: 60px;
  position: relative;
  width: 100%;

  &::after {
    border: 0.4rem solid transparent;
    border-top-color: currentColor;
    content: '';
    opacity: 0.5;
    pointer-events: none;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-25%);
  }

  select {
    width: 100%;
  }
}

div.c7-form__cardexpiry-mm {
  @media screen and (min-width: 46rem) {
    width: 46%;
  }
}

div.c7-form__cardexpiry-yy {
  @media screen and (min-width: 46rem) {
    width: 27%;
  }
}

div.c7-form__cardexpiry-cvv {
  @media screen and (min-width: 46rem) {
    width: 27%;
  }
}

.c7-selectbox {
  & + .c7-selectbox {
    margin-left: 1rem;
  }
}
