//variables
$margin-bottom: 1.5rem !default;

//product club shipment
.c7-products-club-shipment {
  @media screen and (min-width: 32rem) {
    border-bottom: 1px solid #ccc;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 auto 1.5rem;
    max-width: 50rem;
  }
}

.c7-clubpackage-product {
  display: flex;
  flex-direction: row;
  margin-bottom: $margin-bottom;
  padding: 0;
  text-align: left;
  width: 100%;

  @media screen and (min-width: 32rem) {
    width: 49%;

    &--featured {
      width: 100%;
    }
  }

  @media screen and (min-width: 80rem) {
    width: 33%;

    &--featured {
      width: 100%;
    }
  }

  &__photo {
    width: 20%;

    img {
      max-height: 300px;
    }
  }

  &__info {
    padding-left: 5%;
    width: 80%;
  }

  &__title {
    display: block;
    font-size: 1rem;
    line-height: 1.25;
    margin-bottom: 0.5em;
  }

  &__min-max {
    font-size: 0.875rem;
    font-style: italic;
    margin-bottom: 0.25rem;
  }

  &__variant {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 0.5rem;

    &__price {
      font-size: 0.75rem;
      width: 100%;

      @media screen and (min-width: 40rem) {
        font-size: 0.917rem;
      }
    }

    &__message {
      font-size: 0.75rem;
      width: 100%;

      @media screen and (min-width: 40rem) {
        font-size: 0.917rem;
      }
    }

    &__sale-price {
      color: $message-alert-color;
      font-size: 0.875rem;
      padding: 0 0 0.5rem;
      width: 100%;

      strong {
        font-size: 0.917rem;
      }
    }

    &__compare-at-price {
      font-size: 0.875rem;
      margin: 0.5rem 0;
      width: 100%;

      @media screen and (min-width: 40rem) {
        font-size: 1rem;
      }

      span {
        font-size: 0.7em;
        line-height: 0;
        position: relative;
        top: -0.4em;
        vertical-align: baseline;
      }

      &__description {
        color: #999;
        display: inline-block;
        font-size: 0.8125em;
        margin-left: -0.4em;
        padding-left: 1rem;
        position: relative;

        &::before {
          content: '/';
          left: 0.25rem;
          position: absolute;
        }
      }

      &--compare-at-price {
        text-decoration: line-through;
      }
    }

    input[type='text'] {
      margin-right: 0.25rem;
      text-align: center;
      width: 60px;
    }
  }

  &__select {
    background: url('/assets/beta/images/c7-icons/select-background.svg')
      no-repeat center right 12px #f7f4f3;
    background-size: 21px;
    padding-right: 2.5rem;
  }
}

.c7-club-actions {
  margin: 0 auto;
  max-width: 50rem;
  text-align: left;

  &--mobile-only {
    margin-bottom: $margin-bottom;

    @media screen and (min-width: 60rem) {
      display: none;
    }
  }

  &__divider {
    &::before {
      content: '\00a0\00a0|\00a0\00a0\00a0';
    }
  }
}
