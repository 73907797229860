//c7:account__information

//variables
$margin-bottom: 1.5rem !default;
$max-member-width: 50rem !default;

.c7-account__information {
  margin: 0 auto ($margin-bottom * 2);
  max-width: $max-member-width;
  text-align: center;

  &__address {
    padding-bottom: $margin-bottom;
  }
}
