#c7-darioush-receipt {
  .receipt-products-list {
    margin: 0;
    padding-left: 0;

    .receipt-product {
      display: flex;
      padding: 0;

      .receipt-product-description {
        width: 250px;
      }

      .receipt-product-price,
      .receipt-product-shipping {
        width: 180px;
      }
    }
  }
}
